<template>
  <h1>Registration</h1>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Registration",
};
</script>
